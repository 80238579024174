import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem } from "@material-ui/core";
// @material-ui/icons
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import Person from "@material-ui/icons/Person";
import Assignment from "@material-ui/icons/Assignment";
import Face from "@material-ui/icons/Face";
import Build from "@material-ui/icons/Build";
import Reorder from "@material-ui/icons/Reorder";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";

import profile from "assets/img/faces/nick-resize.png";
import { profileUrls } from "assets/urls";

// Profile Picture imports
import mountain1 from "assets/img/nicks_photos/mountain1.webp";
import mountain2 from "assets/img/nicks_photos/mountain2.webp";
import mountain3 from "assets/img/nicks_photos/mountain3.webp";
import acrophobes from "assets/img/nicks_photos/acrophobes.webp";
import angelscrack from "assets/img/nicks_photos/angelscrack.webp";

//Logo imports
import django from "assets/img/logos/django-logo-negative.png";
import android from "assets/img/logos/android.svg";
import postgres from "assets/img/logos/postgresql.svg";
import flow from "assets/img/logos/flow.svg";
import react from "assets/img/logos/react.svg";
import typescript from "assets/img/logos/typescript.svg";
import cordova from "assets/img/logos/cordova.svg";
import jest from "assets/img/logos/jest-0.svg";
import numida from "assets/img/logos/numida-heart.png";
import fivestars from "assets/img/logos/fivestars.png";

// Numida carousel imports
import history from "assets/img/portfolio/history.webp";
import ledgerview from "assets/img/portfolio/ledgerview.webp";
import credit from "assets/img/portfolio/credit.webp";
import numidaHome from "assets/img/portfolio/homepage.webp";
import stock from "assets/img/portfolio/stock.webp";
import numidaPro from "assets/img/portfolio/pro.webp";
import cpay from "assets/img/portfolio/cpay_insert.webp";
import cts from "assets/img/portfolio/cts.webp";

import styles from "assets/jss/material-kit-react/views/profilePage.js";
import typoStyles from "assets/jss/material-kit-react/views/componentsSections/typographyStyle.js";
import tabStyles from "assets/jss/material-kit-react/views/componentsSections/tabsStyle.js";
import headerLinkStyles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import CustomTabs from "../../components/CustomTabs/CustomTabs";

import Tooltip from "@material-ui/core/Tooltip";
import dayjs from "dayjs";
import axios from "axios";
import { GITHUB_EVENT_ENDPOINT } from "api/endpoints";
import { GITHUB_USER_TOKEN } from "keys";

const useStyles = makeStyles(styles);
const useTabStyles = makeStyles(tabStyles);
const useTypoStyles = makeStyles(typoStyles);
const useHeaderLinkStyles = makeStyles(headerLinkStyles);
const logoHeight = 150;
const carouselImageHeight = 200;

const booksRead = [
  { title: "Eloquent JavaScript", author: "Marijn Haverbeke" },
  { title: "Programming JavaScript Applications", author: "Eric Elliott" },
  { title: "The Mythical Man-Month", author: "Fred Brooks" },
  {
    title: "Effective Python: 59 Ways to Write Better Python",
    author: "Brett Slatkin"
  },
  {
    title: "Clean Code: A Handbook of Agile Software Craftmanship",
    author: "Robert Martin"
  },
  {
    title: "Pragmatic Programmer: From Journeyman to Master",
    author: "Andy Hunt and Dave Thomas"
  },
  { title: "Fluent Python", author: "Luciano Ramahlho" }
];

// Add the Rest
const booksToRead = [
  { title: "JavaScript Allonge", author: "Reginald Braithwaite" },
  { title: "Code Complete", author: "Steve McConnell" },
  {
    title: "Head First Design Patterns",
    author: "Elisabeth Freeman and Kathy Sierra"
  },
  { title: "Refactoring", author: "Kent Beck and Martin Fowler" },
  {
    title: "Designing Data-Intensive Applications",
    author: "Martin Kleppmann"
  },
  { title: "Deep Work", author: "Cal Newport" },
  { title: "The Hard Thing About Hard Things", author: "Ben Horowitz" }
];

const dashboardRoutes = [];

// Always want 5 weeks
const FOUR_WEEKS = 28;

function getSquareColor(number) {
  if (number === 0) {
    return "#EBEDF0";
  }
  if (number < 3) {
    return "#C6E48B";
  }
  if (number < 6) {
    return "#7BC96F";
  }
  if (number < 9) {
    return "#239A3B";
  } else {
    return "#196127";
  }
}

function generateThisWeeksDates() {
  const dateArray = [];
  for (let i = 6; i > -1; i--) {
    dateArray.push(
      dayjs()
        .startOf("week")
        .add(i, "days")
        .format("YYYY-MM-DD")
    );
  }
  return dateArray;
}

function generateDateArray(numberOfDays) {
  const dateArray = generateThisWeeksDates();
  const latestSunday = dateArray[dateArray.length - 1];
  for (let i = 1; i < numberOfDays + 1; i++) {
    dateArray.push(
      dayjs(latestSunday)
        .subtract(i, "days")
        .format("YYYY-MM-DD")
    );
  }
  return dateArray;
}

function countUniqueDates(timeStamps) {
  const fiveWeeks = generateDateArray(FOUR_WEEKS);
  const timeStampsByDay = timeStamps.map(timeStamp =>
    dayjs(timeStamp).format("YYYY-MM-DD")
  );
  const dateCountMap = new Map();
  fiveWeeks.forEach(day => {
    dateCountMap.set(day, 0);
  });
  timeStampsByDay.forEach(day => {
    if (dateCountMap.has(day)) {
      const dayCount = dateCountMap.get(day);
      dateCountMap.set(day, dayCount + 1);
    }
  });
  return dateCountMap;
}

function generateDayOfWeekProductivitySquares(dateCountMap, dayOfWeek) {
  const dayOfWeekArr = [];
  dateCountMap.forEach((value, key) => {
    if (dayjs(key).day() === dayOfWeek) {
      dayOfWeekArr.push(value);
    }
  });
  return dayOfWeekArr;
}

// TODO get this going!
function Resume() {
  const classes = useStyles();
  const tabClasses = useTabStyles();
  const headerClasses = useHeaderLinkStyles();
  const navImageClasses = classNames(
    classes.imgRounded,
    classes.imgGallery,
    classes.imgFluid
  );
  const imageClasses = classNames(classes.imgRaised, classes.imgFluid);
  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false
  };
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <h3 className={classes.bodyText}>
            <small>Software Developer @ Numida Technologies</small>
          </h3>
          <CustomTabs
            plainTabs
            headerColor="info"
            tabs={[
              {
                tabName: "Description",
                tabIcon: Face,
                tabContent: (
                  <div>
                    <p className={classes.bodyText}>
                      Touching every part of the stack, I have helped to build
                      and ship many new product features, including: a
                      subscription model, a loan application flow, inventory
                      tracking features and more. The code I have written with
                      Numida is helping thousands of Ugandan small business
                      owners understand and grow their businesesses.
                    </p>
                    <img
                      src={numida}
                      alt={"..."}
                      style={{ height: logoHeight }}
                    />
                  </div>
                )
              },
              {
                tabName: "Features Built",
                tabIcon: Build,
                tabContent: (
                  <Carousel {...carouselSettings}>
                    <div>
                      <img
                        src={numidaHome}
                        alt="Home slide"
                        className="slick-image"
                        style={{
                          height: "auto",
                          width: "auto",
                          maxHeight: carouselImageHeight * 2,
                          maxWidth: carouselImageHeight * 2
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src={history}
                        alt="History slide"
                        className="slick-image"
                        style={{
                          height: "auto",
                          width: "auto",
                          maxHeight: carouselImageHeight * 2,
                          maxWidth: carouselImageHeight * 2
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src={credit}
                        alt="Credit slide"
                        className="slick-image"
                        style={{
                          height: "auto",
                          width: "auto",
                          maxHeight: carouselImageHeight * 2,
                          maxWidth: carouselImageHeight * 2
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src={stock}
                        alt="Stock slide"
                        className="slick-image"
                        style={{
                          height: "auto",
                          width: "auto",
                          maxHeight: carouselImageHeight * 2,
                          maxWidth: carouselImageHeight * 2
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src={ledgerview}
                        alt="Ledger input slide"
                        className="slick-image"
                        style={{
                          height: "auto",
                          width: "auto",
                          maxHeight: carouselImageHeight * 2,
                          maxWidth: carouselImageHeight * 2
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src={numidaPro}
                        alt="Pro slide"
                        className="slick-image"
                        style={{
                          height: "auto",
                          width: "auto",
                          maxHeight: carouselImageHeight * 2,
                          maxWidth: carouselImageHeight * 2
                        }}
                      />
                    </div>
                  </Carousel>
                )
              },
              {
                tabName: "Tech Stack",
                tabIcon: Reorder,
                tabContent: (
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={4}>
                      <img
                        alt="..."
                        src={react}
                        style={{ height: logoHeight }}
                        className={navImageClasses}
                      />
                      <img
                        alt="..."
                        src={flow}
                        style={{ height: logoHeight }}
                        className={navImageClasses}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <img
                        alt="..."
                        src={jest}
                        className={navImageClasses}
                        style={{ height: logoHeight }}
                      />
                      <img
                        alt="..."
                        src={android}
                        style={{ height: logoHeight }}
                        className={navImageClasses}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <img alt="..." src={django} className={navImageClasses} />
                      <img
                        alt="..."
                        src={postgres}
                        style={{ height: logoHeight }}
                        className={navImageClasses}
                      />
                    </GridItem>
                  </GridContainer>
                )
              }
            ]}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <h3 className={classes.bodyText}>
            <small>Hardware/Systems Engineer @</small>
            <small> FiveStars Loyalty</small>
          </h3>
          <CustomTabs
            plainTabs
            headerColor="success"
            tabs={[
              {
                tabName: "Description",
                tabIcon: Face,
                tabContent: (
                  <div>
                    <p className={classes.bodyText}>
                      Jack of all trades, I did everything from writing client
                      code that accessed and logged hardware telemetry, to
                      building automated staging software that allowed our
                      warehouse to stage tens of thousands of tablets. I
                      deployed a data pipeline with corresponding dashboards to
                      monitor the health of our hardware in the field, and built
                      OTA infrastructure that updated the firmware and operating
                      systems of deployed hardware. I even spent time travelling
                      overseas to meet with and audit manufacturers. The work I
                      did with FiveStars significantly reduced the complexity
                      and costs of running a distributed system of 25k+ devices.
                    </p>
                    <img
                      src={fivestars}
                      alt={"..."}
                      style={{ height: logoHeight }}
                    />
                  </div>
                )
              },
              {
                tabName: "Products Serviced",
                tabIcon: Build,
                tabContent: (
                  <Carousel {...carouselSettings}>
                    <div>
                      <img
                        src={cpay}
                        alt="Cpay slide"
                        className="slick-image"
                        style={{
                          height: "auto",
                          width: "auto",
                          maxHeight: carouselImageHeight * 2,
                          maxWidth: carouselImageHeight * 2
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src={cts}
                        alt="CTS slide"
                        className="slick-image"
                        style={{
                          height: "auto",
                          width: "auto",
                          maxHeight: carouselImageHeight * 2,
                          maxWidth: carouselImageHeight * 2
                        }}
                      />
                    </div>
                  </Carousel>
                )
              },
              {
                tabName: "Tech Stack",
                tabIcon: Reorder,
                tabContent: (
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={4}>
                      <img
                        alt="..."
                        src={react}
                        style={{ height: logoHeight }}
                        className={navImageClasses}
                      />
                      <img
                        alt="..."
                        src={typescript}
                        style={{ height: logoHeight }}
                        className={navImageClasses}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <img
                        alt="..."
                        src={cordova}
                        style={{ height: logoHeight }}
                        className={navImageClasses}
                      />
                      <img alt="..." src={django} className={navImageClasses} />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <img
                        alt="..."
                        src={android}
                        style={{ height: logoHeight }}
                        className={navImageClasses}
                      />
                    </GridItem>
                  </GridContainer>
                )
              }
            ]}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default function ProfilePage(props) {
  const [fetched, setFetched] = React.useState(false);
  const [countDateMap, setCountDateMap] = React.useState(new Map());
  const data = [];
  let flattenedData = [];
  if (!fetched) {
    for (let i = 1; i < 11; i++) {
      axios
        .get(`${GITHUB_EVENT_ENDPOINT}?page=${i}`, {
          headers: {
            Authorization: `Bearer ${GITHUB_USER_TOKEN}`
          }
        })

        .then(response => {
          data[i - 1] = response.data;
          if (i === 10) {
            setTimeout(() => {
              data.forEach(dataArray =>
                dataArray.forEach(el => flattenedData.push(el.created_at))
              );
              setCountDateMap(countUniqueDates(flattenedData));
            }, 1000);
          }
        })
        .catch(error => {});
    }
    setFetched(true);
  }
  const emptyDateArray = [0, 0, 0, 0, 0];
  const sundayArray =
    countDateMap.size === 0
      ? emptyDateArray
      : generateDayOfWeekProductivitySquares(countDateMap, 0);
  sundayArray.reverse();
  const mondayArray =
    countDateMap.size === 0
      ? emptyDateArray
      : generateDayOfWeekProductivitySquares(countDateMap, 1);
  mondayArray.reverse();
  const tuesdayArray =
    countDateMap.size === 0
      ? emptyDateArray
      : generateDayOfWeekProductivitySquares(countDateMap, 2);
  tuesdayArray.reverse();
  const wednesdayArray =
    countDateMap.size === 0
      ? emptyDateArray
      : generateDayOfWeekProductivitySquares(countDateMap, 3);
  wednesdayArray.reverse();
  const thursdayArray =
    countDateMap.size === 0
      ? emptyDateArray
      : generateDayOfWeekProductivitySquares(countDateMap, 4);
  thursdayArray.reverse();
  const fridayArray =
    countDateMap.size === 0
      ? emptyDateArray
      : generateDayOfWeekProductivitySquares(countDateMap, 5);
  fridayArray.reverse();
  const saturdayArray =
    countDateMap.size === 0
      ? emptyDateArray
      : generateDayOfWeekProductivitySquares(countDateMap, 6);
  saturdayArray.reverse();
  const classes = useStyles();
  const typoClasses = useTypoStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
    <div>
      <Header
        color="transparent"
        brand="Nick Best"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      <Parallax
        small
        filter
        image={require("assets/img/lake-garibaldi.webp")}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>Nick Best</h3>
                    <h6>{`DEVELOPER @ NUMIDA TECH`}</h6>
                    <Button
                      justIcon
                      link
                      href={profileUrls.github}
                      className={classes.margin5}
                    >
                      <i className={"fab fa-github"} />
                    </Button>
                    <Button
                      justIcon
                      link
                      href={profileUrls.medium}
                      className={classes.margin5}
                    >
                      <i className={"fab fa-medium"} />
                    </Button>
                    <Button
                      justIcon
                      link
                      href={profileUrls.twitter}
                      className={classes.margin5}
                    >
                      <i className={"fab fa-twitter"} />
                    </Button>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.description}>
              <p>
                Progress is the only healthy state of mind. Whether it be
                skiing, climbing, writing or software development, I'm always
                seeking to improve.
              </p>
            </div>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                <NavPills
                  alignCenter
                  color="primary"
                  tabs={[
                    {
                      tabButton: "About",
                      tabIcon: Person,
                      tabContent: (
                        <div>
                          <p>
                            A software developer for Numida Technologies helping
                            to bring digital finance tools to underserved
                            markets. Delighted by the the pleasure of building
                            and creating, while striving for the professionalism
                            required of an engineer. Understanding that a
                            working solution is not enough, writing clean and
                            maintainable code is his main objective. Skiing,
                            climbing, reading, and writing is what excites him
                            outside of work. You can find out more
                            <a href={profileUrls.medium}> here.</a>
                          </p>
                          <div className={classes.regularTitle}>
                            <h3> This month's productivity</h3>
                          </div>
                          <GridContainer style={{ marginLeft: "275px" }}>
                            <GridItem>
                              {sundayArray.map((el, idx) => (
                                <div key={idx}>
                                  <Tooltip title={`${el} contributions`}>
                                    <div
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        background: getSquareColor(el),
                                        border: "3px solid white",
                                        float: "left"
                                      }}
                                    />
                                  </Tooltip>
                                </div>
                              ))}
                            </GridItem>
                            <GridItem>
                              {mondayArray.map((el, idx) => (
                                <div key={idx}>
                                  <Tooltip title={`${el} contributions`}>
                                    <div
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        background: getSquareColor(el),
                                        border: "3px solid white",
                                        float: "left"
                                      }}
                                    />
                                  </Tooltip>
                                </div>
                              ))}
                            </GridItem>
                            <GridItem>
                              {tuesdayArray.map((el, idx) => (
                                <div key={idx}>
                                  <Tooltip title={`${el} contributions`}>
                                    <div
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        background: getSquareColor(el),
                                        border: "3px solid white",
                                        float: "left"
                                      }}
                                    />
                                  </Tooltip>
                                </div>
                              ))}
                            </GridItem>
                            <GridItem>
                              {wednesdayArray.map((el, idx) => (
                                <div key={idx}>
                                  <Tooltip title={`${el} contributions`}>
                                    <div
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        background: getSquareColor(el),
                                        border: "3px solid white",
                                        float: "left"
                                      }}
                                    />
                                  </Tooltip>
                                </div>
                              ))}
                            </GridItem>
                            <GridItem>
                              {thursdayArray.map((el, idx) => (
                                <div key={idx}>
                                  <Tooltip title={`${el} contributions`}>
                                    <div
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        background: getSquareColor(el),
                                        border: "3px solid white",
                                        float: "left"
                                      }}
                                    />
                                  </Tooltip>
                                </div>
                              ))}
                            </GridItem>
                            <GridItem>
                              {fridayArray.map((el, idx) => (
                                <div key={idx}>
                                  <Tooltip title={`${el} contributions`}>
                                    <div
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        background: getSquareColor(el),
                                        border: "3px solid white",
                                        float: "left"
                                      }}
                                    />
                                  </Tooltip>
                                </div>
                              ))}
                            </GridItem>
                            <GridItem>
                              {saturdayArray.map((el, idx) => (
                                <div key={idx}>
                                  <Tooltip title={`${el} contributions`}>
                                    <div
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        background: getSquareColor(el),
                                        border: "3px solid white",
                                        float: "left"
                                      }}
                                    />
                                  </Tooltip>
                                </div>
                              ))}
                            </GridItem>
                          </GridContainer>
                          <small>Via Github's Events API </small>
                        </div>
                      )
                    },
                    {
                      tabButton: "CV",
                      tabIcon: Assignment,
                      tabContent: <Resume />
                    },
                    {
                      tabButton: "Reading List",
                      tabIcon: LibraryBooks,
                      tabContent: (
                        <div className={classes.bodyText}>
                          <h3>Have read:</h3>
                          <List className={classes.list}>
                            {booksRead.map(book => (
                              <ListItem key={book.title}>
                                <h4>
                                  {book.title} by {book.author}
                                </h4>
                              </ListItem>
                            ))}
                          </List>
                          <h3> To read:</h3>
                          <List className={classes.list}>
                            {booksToRead.map(book => (
                              <ListItem key={book.title}>
                                <h4>
                                  {book.title} by {book.author}
                                </h4>
                              </ListItem>
                            ))}
                          </List>
                        </div>
                      )
                    }
                  ]}
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
