import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import axios from "axios";
import dayjs from "dayjs";

import { profileUrls } from "assets/urls";
import { GITHUB_EVENT_ENDPOINT } from "api/endpoints";
import { GITHUB_USER_TOKEN } from "keys";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
// import ProductSection from "./Sections/ProductSection.js";
// import WorkSection from "./Sections/WorkSection.js";

const dashboardRoutes = [];

// Always want 5 weeks
const FOUR_WEEKS = 28;

const useStyles = makeStyles(styles);

function getSquareColor(number) {
  if (number === 0) {
    return "#EBEDF0";
  }
  if (number < 3) {
    return "#C6E48B";
  }
  if (number < 6) {
    return "#7BC96F";
  }
  if (number < 9) {
    return "#239A3B";
  } else {
    return "#196127";
  }
}

function generateThisWeeksDates() {
  const dateArray = [];
  for (let i = 6; i > -1; i--) {
    dateArray.push(
      dayjs()
        .startOf("week")
        .add(i, "days")
        .format("YYYY-MM-DD")
    );
  }
  return dateArray;
}

function generateDateArray(numberOfDays) {
  const dateArray = generateThisWeeksDates();
  const latestSunday = dateArray[dateArray.length - 1];
  for (let i = 1; i < numberOfDays + 1; i++) {
    dateArray.push(
      dayjs(latestSunday)
        .subtract(i, "days")
        .format("YYYY-MM-DD")
    );
  }
  return dateArray;
}

function countUniqueDates(timeStamps) {
  const fiveWeeks = generateDateArray(FOUR_WEEKS);
  const timeStampsByDay = timeStamps.map(timeStamp =>
    dayjs(timeStamp).format("YYYY-MM-DD")
  );
  const dateCountMap = new Map();
  fiveWeeks.forEach(day => {
    dateCountMap.set(day, 0);
  });
  timeStampsByDay.forEach(day => {
    if (dateCountMap.has(day)) {
      const dayCount = dateCountMap.get(day);
      dateCountMap.set(day, dayCount + 1);
    }
  });
  return dateCountMap;
}

function generateDayOfWeekProductivitySquares(dateCountMap, dayOfWeek) {
  const dayOfWeekArr = [];
  dateCountMap.forEach((value, key) => {
    if (dayjs(key).day() === dayOfWeek) {
      dayOfWeekArr.push(value);
    }
  });
  return dayOfWeekArr;
}

export default function LandingPage(props) {
  const [fetched, setFetched] = React.useState(false);
  const [countDateMap, setCountDateMap] = React.useState(new Map());
  const data = [];
  let flattenedData = [];
  if (!fetched) {
    for (let i = 1; i < 11; i++) {
      axios
        .get(`${GITHUB_EVENT_ENDPOINT}?page=${i}`, {
          headers: {
            Authorization: `Bearer ${GITHUB_USER_TOKEN}`
          }
        })

        .then(response => {
          data[i - 1] = response.data;
          if (i === 10) {
            setTimeout(() => {
              data.forEach(dataArray =>
                dataArray.forEach(el => flattenedData.push(el.created_at))
              );
              setCountDateMap(countUniqueDates(flattenedData));
            }, 1000);
          }
        })
        .catch(error => {});
    }
    setFetched(true);
  }
  const emptyDateArray = [0, 0, 0, 0, 0];
  const sundayArray =
    countDateMap.size === 0
      ? emptyDateArray
      : generateDayOfWeekProductivitySquares(countDateMap, 0);
  sundayArray.reverse();
  const mondayArray =
    countDateMap.size === 0
      ? emptyDateArray
      : generateDayOfWeekProductivitySquares(countDateMap, 1);
  mondayArray.reverse();
  const tuesdayArray =
    countDateMap.size === 0
      ? emptyDateArray
      : generateDayOfWeekProductivitySquares(countDateMap, 2);
  tuesdayArray.reverse();
  const wednesdayArray =
    countDateMap.size === 0
      ? emptyDateArray
      : generateDayOfWeekProductivitySquares(countDateMap, 3);
  wednesdayArray.reverse();
  const thursdayArray =
    countDateMap.size === 0
      ? emptyDateArray
      : generateDayOfWeekProductivitySquares(countDateMap, 4);
  thursdayArray.reverse();
  const fridayArray =
    countDateMap.size === 0
      ? emptyDateArray
      : generateDayOfWeekProductivitySquares(countDateMap, 5);
  fridayArray.reverse();
  const saturdayArray =
    countDateMap.size === 0
      ? emptyDateArray
      : generateDayOfWeekProductivitySquares(countDateMap, 6);
  saturdayArray.reverse();
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand=""
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax filter image={require("assets/img/landing-bg.jpg")}>
        <div className={classes.headerContainer}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>Nicholas Best</h1>
              <h4>
                Read what I've <a href={profileUrls.medium}>written</a>,
                discover what I've <a href={profileUrls.github}> built</a>, find
                out more
                <Link to="/profile-page"> about me.</Link>
              </h4>
              <br />
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>

      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.sections}>
          <div className={classes.container}>
            <div className={classes.regularTitle}>
              <h3> This month's productivity</h3>
            </div>
            <GridContainer>
              <GridItem className={classes.alignCenter}>
                {sundayArray.map((el, idx) => (
                  <div key={idx}>
                    <Tooltip title={`${el} contributions`}>
                      <div
                        style={{
                          width: "30px",
                          height: "30px",
                          background: getSquareColor(el),
                          border: "3px solid white",
                          float: "left"
                        }}
                      />
                    </Tooltip>
                  </div>
                ))}
              </GridItem>
              <GridItem>
                {mondayArray.map((el, idx) => (
                  <div key={idx}>
                    <Tooltip title={`${el} contributions`}>
                      <div
                        style={{
                          width: "30px",
                          height: "30px",
                          background: getSquareColor(el),
                          border: "3px solid white",
                          float: "left"
                        }}
                      />
                    </Tooltip>
                  </div>
                ))}
              </GridItem>
              <GridItem>
                {tuesdayArray.map((el, idx) => (
                  <div key={idx}>
                    <Tooltip title={`${el} contributions`}>
                      <div
                        style={{
                          width: "30px",
                          height: "30px",
                          background: getSquareColor(el),
                          border: "3px solid white",
                          float: "left"
                        }}
                      />
                    </Tooltip>
                  </div>
                ))}
              </GridItem>
              <GridItem>
                {wednesdayArray.map((el, idx) => (
                  <div key={idx}>
                    <Tooltip title={`${el} contributions`}>
                      <div
                        style={{
                          width: "30px",
                          height: "30px",
                          background: getSquareColor(el),
                          border: "3px solid white",
                          float: "left"
                        }}
                      />
                    </Tooltip>
                  </div>
                ))}
              </GridItem>
              <GridItem>
                {thursdayArray.map((el, idx) => (
                  <div key={idx}>
                    <Tooltip title={`${el} contributions`}>
                      <div
                        style={{
                          width: "30px",
                          height: "30px",
                          background: getSquareColor(el),
                          border: "3px solid white",
                          float: "left"
                        }}
                      />
                    </Tooltip>
                  </div>
                ))}
              </GridItem>
              <GridItem>
                {fridayArray.map((el, idx) => (
                  <div key={idx}>
                    <Tooltip title={`${el} contributions`}>
                      <div
                        style={{
                          width: "30px",
                          height: "30px",
                          background: getSquareColor(el),
                          border: "3px solid white",
                          float: "left"
                        }}
                      />
                    </Tooltip>
                  </div>
                ))}
              </GridItem>
              <GridItem>
                {saturdayArray.map((el, idx) => (
                  <div key={idx}>
                    <Tooltip title={`${el} contributions`}>
                      <div
                        style={{
                          width: "30px",
                          height: "30px",
                          background: getSquareColor(el),
                          border: "3px solid white",
                          float: "left"
                        }}
                      />
                    </Tooltip>
                  </div>
                ))}
              </GridItem>
            </GridContainer>
            <small>Via Github's Events API </small>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
